<template>
  <v-container
    id="extended-tables-view"
    fluid
    tag="section"
  >
    <material-card
      icon="fas fa-laptop"
      icon-small
      color="primary"
      :title="$t('pageTitles.Page/SectionPageList')"
    >
      <v-simple-table class="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('forms.label') }}</th>
          </tr>
        </thead>
        <tbody v-if="!pages || !pages.length">
          <tr>
            <td colspan="2">
              <i
                v-if="processing"
                class="fas fa-spin fa-spinner"
              ></i>
              <template v-else>
                {{ $t('forms.nodata') }}
              </template>
            </td>
          </tr>
        </tbody>

        <draggable
          v-else
          v-model="pages"
          tag="tbody"
          handle=".fa-arrows-alt"
          @change="sortHandler"
        >
          <tr
            v-for="page in pages"
            :key="`sections-pages-list-${page.id}`"
          >
            <td>
              <i class="fas fa-arrows-alt"></i>
            </td>
            <td>{{ page.label }}</td>
          </tr>
        </draggable>
      </v-simple-table>
    </material-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import serverError from '../../mixins/serverError';
export default {
  name: 'SectionPageList',

  mixins: [serverError],

  data: () => ({
    pages: [],
  }),

  computed: {
    ...mapGetters({
      sectionPages: 'page/getPages',
      processing: 'global/getProcessing',
    }),
  },

  created() {
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.pages = [];
      this.$store.dispatch('page/fetchPages', { params: { 'filter[section_id]': this.$route.params.id, sort: 'order' } })
        .then(() => {
          this.pages = [...this.sectionPages];
        });
    },
    sortHandler() {
      const order = this.pages.map(page => page.id);
      const payload = { order };
      this.$store.dispatch('page/reorderPages', payload)
        .then(response => {
          this.pages = response.data.data;
        })
        .catch(() => this.$toasted.error(this.$t('error_happened')));
    },
  },
}
</script>

<style lang="scss">
.fa-arrows-alt {
  cursor: grab;
}

.section-preview {
  max-height: 40px;
}
</style>
